import { ajax } from '@/utils/request';

// 查询文件类型及数量
export async function getFileBusiType(extra) {
    const result = await ajax({
      method: 'post',
      url: '/file/countByType',
      ...extra,
    }, [], []);
    return result;
}
//根据类型获取文件
export async function getFilesByType(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/queryByType',
    ...extra,
  }, [], []);
  return result;
}

export const searchFile = (extra) => {
  return ajax({
    url: '/file/fileField/search',
    method: 'post',
    ...extra,
  })
}

//获取用户所有文件
export async function searchFilesByUser(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/search',
    ...extra,
  }, [], []);
  return result;
}

//关联用户文档和数据
export async function relateFile(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/relateData',
    ...extra,
  }, [], []);
  return result;
}

//关联用户文档和数据(重复的文件返回已关联的文件)
export async function relateDataDuplicate(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/relateDataDuplicate',
    ...extra,
  }, [], []);
  return result;
}


//批量关联用户文档和数据
export async function batchRelateFile(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/batchRelateData',
    ...extra,
  }, [], []);
  return result;
}

//新建文档
export async function createDoc(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/add',
    ...extra,
  }, [], []);
  return result;
}
//新建文档
export async function fileAddWithout(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/addWithoutSave',
    ...extra,
  }, [], []);
  return result;
}

//修改文件业务类型
export async function editFileBusinessType(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/editFileBusinessType',
    ...extra,
  }, [], []);
  return result;
}

//下载文件
export async function downloadFile(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/download',
    responseType: 'blob',
    ...extra,
  },{
    getContext: true,
  });
  return result;
}

//复制文件
export async function copyFile(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/copy',
    ...extra,
  }, [], []);
  return result;
}

//删除文件
export async function deleteFile(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/delete',
    ...extra,
  }, [], []);
  return result;
}

//批量 删除文件
export async function batchDelFile(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/batchRemove',
    ...extra,
  }, [], []);
  return result;
}

//上传后批量保存文件
export async function batchSaveFile(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/batchSave',
    ...extra,
  }, [], []);
  return result;
}
//上传后批量保存文件（不需要记录动态）
export async function batchSaveFileNoOperateEvent(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/batchSaveFileNoOperateEvent',
    ...extra,
  }, [], []);
  return result;
}

//上传后批量保存文件 wtf up ??
export async function batchSaveFileUp(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/batchSaveUp',
    ...extra,
  }, [], []);
  return result;
}

//查询用户自身文件
export async function querySelfFile(dataId, duplicate = false) {
  const result = await ajax({
    method: 'get',
    url: `/file/querySelfFiles?dataId=${dataId}&duplicate=${duplicate}`,
  }, [], []);
  return result;
}

//查询用户自身文件
export async function querySelfFileNoId(extra) {
  const result = await ajax({
    method: 'get',
    url: `/file/querySelfFiles`,
    ...extra
  }, [], []);
  return result;
}

//删除前确认
export async function queryFileQuote(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/checkRelated',
    ...extra,
  }, [], []);
  return result;
}

//获取各数据类型的 文档类型
export async function queryAllFileTypes(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/businessType/query',
    ...extra,
  }, [], []);
  return result;
}

//查询文件树状结构
export async function fileQueryTree(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/queryTree',
    ...extra,
  }, [], []);
  return result;
}
//保存排序
export async function fileViewSave(extra) {
  const result = await ajax({
    method: 'post',
    url: '/fileView/save',
    ...extra,
  }, [], []);
  return result;
}
//更新排序
export async function fileViewUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: '/fileView/update',
    ...extra,
  }, [], []);
  return result;
}
//查询排序
export async function fileViewQueryByUserId(extra) {
  const result = await ajax({
    method: 'post',
    url: '/fileView/queryByUserId',
    ...extra,
  }, [], []);
  return result;
}
//新建文件夹
export async function fileAddFolder(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/addFolder',
    ...extra,
  }, [], []);
  return result;
}
// 文件发消息
export const sendMessage = (dataId, folderName, fileToken = '', fileType = 'folder') => {
  return ajax({
    url: '/file/batchSaveSendMsg',
    method: 'post',
    data: [
      {
        dataId,
        fileName: folderName,
        fileToken,
        fileType,
      }
    ],
  })
}
//删除文件夹
export async function fileDeleteFolder(extra) {
  const result = await ajax({
    method: 'get',
    url: `/file/deleteFolder?folderId=${extra}`,
  }, [], []);
  return result;
}
//移动文件和文件夹
export async function fileMove(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/move',
    ...extra
  }, [], []);
  return result;
}
// 批量移动文件和文件夹
export async function fileBatchMove(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/batchMove',
    ...extra
  }, [], []);
  return result;
}
// 批量移动文件和文件夹
export async function fileBatchUpdateFileTag(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/batchUpdateFileTag',
    ...extra
  }, [], []);
  return result;
}
// 上传文件夹
export async function fileUpdateFolder(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/updateFolder',
    ...extra
  }, [], []);
  return result;
}
// 缓存下载文件
export async function fileDownloadCache(extra) {
  const result = await ajax({
    method: 'post',
    url: '/file/downloadCache',
    ...extra
  }, [], []);
  return result;
}
