import {ajax} from '@/utils/request';

// 查询详情
export async function getProjectDetail(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/${type}/queryInfo`,
    ...extra,
  },{
    getContext: true,
  });
  return result;
}

// 查询详情(无权限，目前只有基金 fund 支持)
export async function getProjectDetailNoAuth(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/${type}/queryInfoNoAuth`,
    ...extra,
  },{
    getContext: true,
  });
  return result;
}

// 投票查询详情
export async function getvoteProjectDetail(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/beforeProject/queryInfoByVote`,
    ...extra,
  }, [], []);
  return result;
}
// 投票查询详情(不走权限)
export async function getvoteProjectDetailLoginSkip(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/beforeProject/queryInfoByVoteLoginSkip`,
    ...extra,
  }, [], []);
  return result;
}

// 获取数据参与人
export async function getDataMember(extra) {
  const result = await ajax({
    method: 'get',
    url: `/datarights/findByDataId`,
    ...extra
  }, [], []);
  return result;
}

// 批量添加协作人员
export async function updateDataMember(extra) {
  const result = await ajax({
    method: 'post',
    url: '/datarights/batchAddParticipants',
    ...extra,
  }, [], []);
  return result;
}

// 批量更新负责人
export async function updateDataOwner(extra) {
  const result = await ajax({
    method: 'post',
    url: '/datarights/batchUpdateOwner',
    ...extra,
  }, [], []);
  return result;
}

// 收藏/取消收藏
export async function favourite(extra) {
  const result = await ajax({
    method: 'post',
    url: '/metadata/dataCollect/collect',
    ...extra,
  }, [], []);
  return result;
}

// 更新成员权限
export async function updatePersonPermission(extra) {
  const result = await ajax({
    method: 'post',
    url: '/datarights/updatePermission',
    ...extra,
  }, [], []);
  return result;
}

export async function getAllUsers(extra) {
  const result = await ajax({
    method: 'post',
    url: '/user/findUsers',
    ...extra,
  }, [], []);
  return result;
}
// 支持拼音搜索人员
export async function getAllUsersByPinyin(extra) {
  const result = await ajax({
    method: 'post',
    url: '/user/findByPinyinName',
    ...extra,
  }, [], []);
  return result;
}

// 根据名称搜索用户和部门
export async function findOrganizationByName(extra) {
  const result = await ajax({
    method: 'get',
    url: `/user/findOrganizationByName?name=${extra}`,
  }, [], []);
  return result;
}
// 根据名称搜索用户和部门（支持拼音搜索）
export async function findOrganizationByPinyin(extra, deptId) {
  const result = await ajax({
    method: 'get',
    url: `/user/findOrganizationByPinyin?name=${extra}&deptId=${deptId}`,
  }, [], []);
  return result;
}

// 删除成员
export async function deleteTeamPerson(extra) {
  const result = await ajax({
    method: 'post',
    url: `/datarights/deleteMember`,
    ...extra
  });
  return result;
}

// 查询当前项目下用户角色、权限
export async function findMyselfByDataId(extra) {
  const result = await ajax({
    method: 'get',
    url: `/datarights/findMyselfByDataId`,
    ...extra
  });
  return result;
}

// 批量查询数据的负责人
export async function checkDataRights(extra) {
  const result = await ajax({
    method: 'post',
    url: '/datarights/checkDataRights',
    ...extra
  },);
  return result;
}

// 批量查询数据的负责人(不同businessCode同步查)
export async function batchCheckDataRights(extra) {
  const result = await ajax({
    method: 'post',
    url: '/datarights/getDataRights',
    ...extra
  }, [], []);
  return result;
}

//获取cloud跳转地址
export async function getCloudUrl(extra, otherConfig) {
  const result = await ajax({
    method: 'get',
    url: '/insight/getCloudUrl/:type/:dataId',
    ...extra,
  }, otherConfig);
  return result;
}

//获取分享模板
export async function getShareTemplate(extra, otherConfig) {
  const result = await ajax({
    method: 'get',
    url: '/message/share',
    ...extra,
  }, otherConfig);
  return result;
}

//根据名称获取已投公司
export async function getInvestedCompByName(extra,code='investedCompany') {
  const result = await ajax({
    method: 'post',
    url: `/${code}/queryByFullName`,
    ...extra,
  });
  return result;
}

export async function unLockData(extra) {
  const result = await ajax(
    {
      method: 'post',
      url: `/investFlow/instance/unlock`,
      ...extra,
    },
  )
  return result
}

export async function lockData(extra) {
  const result = await ajax(
    {
      method: 'post',
      url: `/investFlow/instance/lock`,
      ...extra,
    },
  )
  return result
}

export async function getBCodeById(extra) {
  const result = await ajax(
    {
      method: 'get',
      url: `/mixData/queryBusinessCodeByDataId`,
      ...extra,
    },
  )
  return result
}

// 查询该用户角色在当前台账的权限

export async function checkBookPermission(extra) {
  const result = await ajax(
    {
      method: 'post',
      url: `/role/bookPermission`,
      ...extra,
    },
  )
  return result
}

// 切换币种
export async function updateCurrency(code, extra) {
  const result = await ajax(
    {
      method: 'post',
      url: `/${code}/updateCurrency`,
      ...extra,
    },
  )
  return result
}

// 校验币种和项目币种是否一致
export async function checkEnableRelate(extra) {
  const result = await ajax(
    {
      method: 'get',
      url: `/beforeProject/checkEnableRelate`,
      ...extra,
    },
  )
  return result
}

// 获取投前投后关联的公司列表
export async function fetchRealtedCompanys(extra) {
  const result = await ajax({
    method: 'post',
    url: `/projectRelate/query`,
    ...extra,
  }, [], []);
  return result;
}
// 搜索公司列表
export async function searchRealtedCompanys( extra) {
  const result = await ajax(
    {
      method: 'post',
      url: `/projectRelate/queryByName`,
      ...extra,
    },
  )
  return result
}
// 保存关联公司列表
export async function saveRealtedCompanys( extra) {
  const result = await ajax(
    {
      method: 'post',
      url: `/projectRelate/save`,
      ...extra,
    },
  )
  return result
}

// 合投机构分析
export async function getPartnerAnalyse() {
  const result = await ajax({
    method: 'get',
    url: `/partner/analyse`,
  }, [], []);
  return result;
}

// 分享项目到组织
export async function shareProject( extra) {
  const result = await ajax(
    {
      method: 'post',
      url: `/share/send`,
      ...extra,
    },
  )
  return result
}
// 查询上一次分享的字段和关联组织
export async function lastShareDetail( extra) {
  const result = await ajax(
    {
      method: 'post',
      url: `/share/latest`,
      ...extra,
    },
  )
  return result
}
// 根据分享ID获取分享详情
export async function fetchShareDetails(id) {
  const result = await ajax({
    method: 'get',
    url: `/share/detail/${id}`,
  }, {
    getContext: true,
  });
  return result;
}

// 在入库投资云的时候，匹配字段信息
export async function matchProjectInfo( extra) {
  const result = await ajax(
    {
      method: 'post',
      url: `/mixData/matchProjectInfo`,
      ...extra,
    },
  )
  return result
}

// 获取投后固定分享的几个字段配置

export async function fetchStaticFieldConfig(type, extra) {
  const result = await ajax({
    method: 'get',
    url: `/field/${type}`,
    ...extra,
  }, [], []);
  return result;
}