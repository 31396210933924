import { ajax } from '@/utils/request'

// 指标库-查询所有口径
export async function quotaCaliberQueryList(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaCaliber/queryList`,
    data,
  }, [], []);
  return result;
}

// 指标库-查询所有口径(包括删除的)
export async function quotaCaliberQueryAll(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaCaliber/queryAll`,
    data,
  }, [], []);
  return result;
}
// 指标库-查询所有口径(包括删除的)
export async function quotaCaliberQueryAllLoginSkip(tenantId) {
  const result = await ajax({
    method: 'post',
    url: `/quotaCaliber/queryAllLoginSkip?tenantId=${tenantId}`,
  }, [], []);
  return result;
}

// 指标库-新增口径
export async function quotaCaliberSave(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaCaliber/save`,
    data,
  }, [], []);
  return result;
}

// 指标库-更新口径
export async function quotaCaliberUpdate(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaCaliber/update`,
    data,
  }, [], []);
  return result;
}
// 指标库-更新口径排序
export async function quotaCaliberUpdateOrder(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaCaliber/updateOrder`,
    data,
  }, [], []);
  return result;
}

// 指标库-删除口径
export async function quotaCaliberDelete(id) {
  const result = await ajax({
    method: 'get',
    url: `/quotaCaliber/delete?id=${id}`,
  }, [], []);
  return result;
}

// 指标库-分类列表
export async function fetchTabsList() {
  const result = await ajax({
    method: 'get',
    url: `/quotaType/queryList?name=`,
  }, [], []);
  return result;
}

// 指标有没有被使用
export async function getQuotaUsed(id) {
  const result = await ajax({
    method: 'get',
    url: `/quota/analysis/config/quotaUsed?id=${id}`,
  }, [], []);
  return result;
}

// 指标库-分类列表
export async function queryBusinessTypeList(extra) {
  const result = await ajax({
    method: 'post',
    url: `/businessType/queryBusinessTypeList`,
    ...extra,
  }, [], []);
  return result;
}

// 指标库-分类新增
export async function addTabsList(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quotaType/batchAdd`,
    ...extra,
  }, [], []);
  return result;
}

// 指标库-分类批量更新
export async function updateTabsList(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quotaType/batchUpdate`,
    ...extra,
  }, [], []);
  return result;
}

// 指标库-分类批量删除
export async function delTabsList(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quotaType/batchDelete`,
    ...extra,
  }, [], []);
  return result;
}

// 指标库-列表
export async function fetchIndicatorList(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quota/queryList`,
    ...extra,
  }, [], []);
  return result;
}

// 指标库-新建指标
export async function newIndicator(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quota/add`,
    ...extra,
  }, [], []);
  return result;
}

// 指标库-编辑指标
export async function updateIndicator(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quota/update`,
    ...extra,
  }, [], []);
  return result;
}

// 指标库-更新指标状态
export async function updateStatusIndicator(data) {
  const result = await ajax({
    method: 'post',
    url: `/quota/updateStatus`,
    data,
  }, [], []);
  return result;
}

// 指标库-更新指标状态
export async function updateAssetTypeIdIndicator(data) {
  const result = await ajax({
    method: 'post',
    url: `/quota/updateAssetTypeId`,
    data,
  }, [], []);
  return result;
}

// 指标库-批量更新指标
export async function updateIndicatorList(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quota/batchUpdateOrder`,
    ...extra,
  }, [], []);
  return result;
}

// 指标库-删除指标
export async function deleteIndicator(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quota/delete`,
    ...extra,
  }, [], []);
  return result;
}

// 指标库-查询文件类型
export async function fetchFileType(extra) {
  const result = await ajax({
    method: 'post',
    url: `/file/businessType/manager/query`,
    ...extra,
  }, [], []);
  return result;
}
// 查询个人视图  /functionView/queryByUserId 
export async function functionViewQueryByUserId(extra) {
  const result = await ajax({
    method: 'post',
    url: `/functionView/queryByUserId`,
    ...extra,
  }, [], []);
  return result;
}
// 关键指标查询用户视图--变更资产类型后
export async function functionViewQuotaQueryByUserId(extra) {
  const result = await ajax({
    method: 'post',
    url: `/functionViewQuota/queryByUserId`,
    ...extra,
  }, [], []);
  return result;
}
// 根据视图ID查询视图条件
export async function functionViewQueryById(extra) {
  const result = await ajax({
    method: 'post',
    url: `/functionView/queryById`,
    ...extra,
  }, [], []);
  return result;
}
// 改版资产类型后保存的视图
export async function fetchViewQuotaUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: `/functionViewQuota/update`,
    ...extra,
  }, [], []);
  return result;
}
//  切换资产类型时更新完视图再获取目的地资产类型下的视图
export async function fetchAssetView(extra) {
  const result = await ajax({
    method: 'post',
    url: `/functionView/queryByAssetTypeId`,
    ...extra,
  }, [], []);
  return result;
}
// 保存个人视图
export async function functionViewUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: `/functionView/update`,
    ...extra,
  }, [], []);
  return result;
}
//新建个人视图 functionView/insert
export async function functionViewInsert(extra) {
  const result = await ajax({
    method: 'post',
    url: `/functionView/insert`,
    ...extra,
  }, [], []);
  return result;
}
//查询指标列表 /quotaData/queryPage
export async function quotaDataQueryPage(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/queryPage`,
    ...extra,
  }, [], []);
  return result;
}
// 搜索项目
export async function quotaDataSearchData(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/searchData`,
    ...extra,
  }, [], []);
  return result;
}
// 导出项目
export async function exportPage(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/exportPage`,
    ...extra,
  }, [], []);
  return result;
}

// 台账页 权益变更 
// 查询个人视图投前投后查询视图 businessView/queryBusinessViewDetail
export async function queryBusinessViewDetail(extra,isbefore=true) {
  const result = await ajax({
    method: 'post',
    url:`/businessView/queryBusinessViewDetail`,
    ...extra,
  }, [], []);
  return result;
}
// 保存视图
export async function createBusinessView(extra) {
  const result = await ajax({
    method: 'post',
    url:`/businessView/createBusinessView`,
    ...extra,
  }, [], []);
  return result;
}
// 查询枚举时间
export async function queryRangeDateTime(extra) {
  const result = await ajax({
    method: 'post',
    url:`/businessView/queryRangeDateTime`,
    ...extra,
  }, [], []);
  return result;
}
export async function queryTransactionView(extra,code,isbefore=true) {
  let url = ''
  if(code =='coin'){
    url = '/transactionCoin/queryTransactionCoinByView'
  }else if(code =='fof'){
    url = '/transactionFof/queryTransactionFofByView'
  }else{
    url = isbefore? `/transactionEip/queryTransactionEipByView`: `/transactionPe/queryTransactionPeByView`
  }
  const result = await ajax({
    method: 'post',
    url,
    ...extra,
  }, [], []);
  return result;
}
export async function queryTransactionSumByView(extra,code,isbefore=true) {
  let url = ''
  if(code =='coin'){
    url = '/transactionCoin/queryTransactionSumByView'
  }else if(code =='fof'){
    url = '/transactionFof/queryTransactionSumByView'
  }else{
    url = isbefore? `/transactionEip/queryTransactionSumByView`: `/transactionPe/queryTransactionSumByView`
  }
  const result = await ajax({
    method: 'post',
    url,
    ...extra,
  }, [], []);
  return result;
}
export async function queryExistIpoView(extra,code,isbefore=true) {
  let url = ''
  if(code =='coin'){
    url = '/transactionCoin/existIpoView'
  }else if(code =='fof'){
    url = '/transactionFof/existIpoView'
  }else{
    url = isbefore? `/transactionEip/existIpoView`: `/transactionPe/existIpo`
  }
  const result = await ajax({
    method: 'post',
    url,
    ...extra,
  }, [], []);
  return result;
}
export async function exportTransactionView(extra,code,isbefore=true) {
  let url = ''
  if(code =='coin'){
    url = '/transactionCoin/exportTransactionView'
  }else if(code =='fof'){
    url = '/transactionFof/exportTransactionView'
  }else{
    url = isbefore? `/transactionEip/existIpoView`: `/transactionPe/exportTransactionView`
  }
  const result = await ajax({
    method: 'post',
    url,
    ...extra,
  }, [], []);
  return result;
}

// 获取后台配置-指标分析-指标配置
export async function fetchAnalysyQuotaConfig(extra) {
  const result = await ajax({
    method: 'post',
    url:`/quota/analysis/config`,
    ...extra,
  }, [], []);
  return result;
}

// 获取后台配置-指标分析-获取股权指标
export async function queryListByInvestedCompany(extra) {
  return  await ajax({
    method: 'post',
    url:`/quota/queryListByInvestedCompany`,
    ...extra,
  }, [], []);;
}

// 获取后台配置-指标分析-修改配置
export async function updateConfig(extra) {
  return  await ajax({
    method: 'post',
    url:`/quota/analysis/config/update`,
    data:extra
  }, [], []);;
}

// 前台指标分析获取最新一期【实际】口径的报告期

export async function getLastestReport(extra) {
  const result = await ajax({
    method: 'get',
    url: `/quota/analysis/latestActualReport`,
    ...extra,
  }, [], []);
  return result;
}
