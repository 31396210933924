import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/demo',
    component: ()=> import('@/components/customDateRange/index.vue')
  },
  {
    path: '/demo2',
    component: ()=> import('@/components/concatTable/index.vue')
  },
  {
    path: '/majiang',
    component: ()=> import('@/views/majiang/index.vue')
  },
  {
    path: '/test',
    component: ()=> import('@/views/test/index.vue')
  },
  {
    path: '/test3',
    component: ()=> import('@/views/test/testLocal3.vue')
  },
  {
    path: '/graph',
    name: 'test',
    component: ()=> import('@/views/afterInv/holdInfo/details.vue')
  },
  {
    path: '/login/:nextpath',
    name: 'login',
    component: ()=> import('@/views/login.vue')
  },
  // 主应用
  {
    path: '/appIntroduce',
    name: 'appIntroduce',
    component: ()=> import('@/views/appIntroduce/index.vue')
  },
  // c_capital 定制
  {
    path: '/cCapital',
    name: 'cCapital',
    component: ()=> import('@/views/cCapital/index.vue')
  },

  // 后台管理
  {
    path: '/manage',
    name: 'manage',
    component: ()=> import('@/views/sysManage/list.vue')
  },
  // 投前
  {
    path: '/before',
    name: 'before',
    component: ()=> import('@/views/beforeInv/list.vue')
  },
  // 投后
  {
    path: '/after',
    name: 'after',
    component: () => import('@/views/afterInv/list.vue')
  },
  // 基金
  {
    path: '/fund',
    name: 'fund',
    component: () => import('@/views/fundInv/list.vue')
  },
  // 投资者
  {
    path: '/lpinvestor',
    name: 'lpinvestor',
    component: () => import('@/views/lpInvestor/list.vue')
  },
  // 投资云助手
  {
    path: '/helper',
    name: 'helper',
    component: () => import('@/views/helper/index.vue')
  },
  // 基金分析
  {
    path: '/fundanalyze',
    name: 'fundanalyze',
    component: () => import('@/views/fundAnalyze/index.vue')
  },
  // 数据收集
  {
    path: '/datacollect',
    name: 'datacollect',
    component: () => import('@/views/dataCollect/index.vue')
  },
  // 数据收集填报外链
  {
    path: '/dataReport',
    name: 'dataReport',
    component: () => import('@/views/dataCollect/dataReport.vue')
  },
  
  // 项目动态设置
  {
    path: '/:appname/setnotify',
    name: 'setnotify',
    component: () => import('@/views/setNotify/index.vue')
  },
  // 获取消息内容
  {
    path: '/:appname/message',
    name: 'getmessage',
    component: () => import('@/views/getMsg/index.vue')
  },
  // 没有数据权限
  {
    path: '/:appname/nodataauth',
    name: 'nodataauth',
    component: () => import('@/views/noAuth/dataAuth.vue')
  },
  //后台关闭时跳转
  {
    path: '/:appname/closed',
    name: 'closed',
    component: () => import('@/views/noData/closed.vue')
  },
  // 没有应用权限
  {
    path: '/noappauth',
    name: 'noappauth',
    component: () => import('@/views/noAuth/appAuth.vue')
  },
  // 数据不存在
  {
    path: '/:appname/nodata',
    name: 'nodata',
    component: () => import('@/views/noData/index.vue')
  },
  // 引导页
  {
    path: '/:appName/guidepage',
    name: 'guidepage',
    component: () => import('@/views/guidePage/index.vue')
  },
  //cloud应用跳转
  {
    path: '/cloud',
    name: 'cloud',
    component: () => import('@/views/cloud/index.vue')
  },
  //舆情分析查看详情列表
  {
    path: '/:appname/publicSentiment',
    name: 'publicSentiment',
    component: () => import('@/views/publicSentiment/index.vue')
  },
  //舆情分析动态设置
  {
    path: '/:appname/setproject',
    name: 'setproject',
    component: () => import('@/views/publicSentiment/setproject.vue')
  },
  //投票消息提示
  {
    path: '/:appname/addvote',
    name: 'addvote',
    component: () => import('@/views/addvoteproject/index.vue')
  },
  //投票消息提示(无需登录)
  {
    path: '/addvotenoauth',
    name: 'addvotenoauth',
    component: () => import('@/views/addvoteproject/indexNoAuth.vue')
  },
  //投票链接(无需登录)
  {
    path: '/addvoteurl',
    name: 'addvoteurl',
    component: () => import('@/views/voteUrl/index.vue')
  },
  //默认页面
  {
    path: '/',
    name: 'default',
    component: () => import('@/views/default/index.vue')
  },
  // 分享详情（分享到关联组织后，通过分享链接访问的页面地址）
  {
    path: '/:appname/shareDetails',
    name: 'shareDetails',
    component: () => import('@/views/components/common/share/shareDetails.vue')
  },
  // 线索填报页
  {
    path: '/c/:shortCode',
    name: 'clueFilling',
    component: () => import('@/views/clueFillings/index.vue')
  },
]

export default routes
